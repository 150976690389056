var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "modal",
        {
          attrs: {
            name: "vodAlertModal",
            width: "50%",
            height: "auto",
            "max-height": 550,
            scrollable: true,
            adaptive: true,
          },
        },
        [
          _c("div", { staticClass: "relative mb-10" }, [
            _c("img", {
              staticClass: "close_btn h-3 w-3 float-left absolute",
              attrs: { src: require("@/assets/image/close_icon_gray.svg") },
              on: {
                click: function ($event) {
                  return _vm.vodAleartHide()
                },
              },
            }),
          ]),
          _c("div", { staticClass: "w-7/12 text-center mx-auto mb-4" }, [
            _c("p", { staticClass: "text-sm font-bold" }, [
              _vm._v("You need to sign a contract when purchasing."),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "w-6/12 mx-auto mb-8" },
            [
              _c(
                "LinkButton",
                {
                  attrs: { classList: "px-2 py-2 text-center" },
                  on: {
                    clickButton: function ($event) {
                      return _vm.vodAleartHide()
                    },
                  },
                },
                [_vm._v("OK")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }