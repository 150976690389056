var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-screen" },
    [
      _vm._m(0),
      _c("div", { staticClass: "border-b border-brown-grey" }),
      _c(
        "ValidationObserver",
        {
          ref: "createForm",
          staticClass: "w-10/12 mx-auto",
          attrs: { tag: "div" },
        },
        [
          _c(
            "div",
            { staticClass: "container mx-auto mt-6" },
            [
              _c("InputConsumerFields", {
                attrs: {
                  fieldType: "regist",
                  otherErrors: _vm.otherFieldErrors,
                },
                on: { setTelContryCode: _vm.setTelContryCode },
                model: {
                  value: _vm.consumer,
                  callback: function ($$v) {
                    _vm.consumer = $$v
                  },
                  expression: "consumer",
                },
              }),
              _c(
                "div",
                {
                  staticClass: "container mx-auto my-6 grid grid-cols-12 gap-6",
                },
                [
                  _c("ValidationProvider", {
                    staticClass: "col-start-3 col-span-9",
                    attrs: { tag: "div", rules: "", name: "Contacted sales" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var errors = ref.errors
                          return [
                            _c(
                              "FlCheckbox",
                              {
                                attrs: { value: true },
                                model: {
                                  value: _vm.consumer.accept_sales,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.consumer, "accept_sales", $$v)
                                  },
                                  expression: "consumer.accept_sales",
                                },
                              },
                              [
                                _vm._v(
                                  "I have been contacted from a filmination sales representative."
                                ),
                              ]
                            ),
                            _c(
                              "p",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: errors.length,
                                    expression: "errors.length",
                                  },
                                ],
                                staticClass: "text-xss text-red-600 mt-1",
                              },
                              [_vm._v(" " + _vm._s(errors[0]) + " ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "container mb-6 h-96 mx-auto grid grid-cols-12 gap-6",
                },
                [
                  _c("FlShowTac", {
                    staticClass: "col-start-3 col-span-10",
                    attrs: { userType: "consumer" },
                    model: {
                      value: _vm.consumer.agree_to_terms,
                      callback: function ($$v) {
                        _vm.$set(_vm.consumer, "agree_to_terms", $$v)
                      },
                      expression: "consumer.agree_to_terms",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "container mx-auto mb-10 grid grid-cols-12 gap-6",
                },
                [
                  _c("ValidationProvider", {
                    staticClass: "col-start-3 col-span-9",
                    attrs: {
                      tag: "div",
                      rules: { required: true },
                      name: "Contacted sales",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var errors = ref.errors
                          return [
                            _c(
                              "FlCheckbox",
                              {
                                attrs: { value: true },
                                model: {
                                  value: _vm.acceptPrivacyPolicy,
                                  callback: function ($$v) {
                                    _vm.acceptPrivacyPolicy = $$v
                                  },
                                  expression: "acceptPrivacyPolicy",
                                },
                              },
                              [
                                _vm._v("I have read and agreed to the "),
                                _c(
                                  "a",
                                  {
                                    staticClass: "underline font-semibold",
                                    attrs: {
                                      href: "/privacy-policy",
                                      target: "_blank",
                                    },
                                  },
                                  [_vm._v("privacy policy.")]
                                ),
                              ]
                            ),
                            _c(
                              "p",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: errors.length,
                                    expression: "errors.length",
                                  },
                                ],
                                staticClass: "text-xss text-red-600 mt-1",
                              },
                              [_vm._v(" " + _vm._s(errors[0]) + " ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("div", [
                _c(
                  "div",
                  { staticClass: "w-5/12 mx-auto px-4 mb-6" },
                  [
                    _c(
                      "LinkButton",
                      {
                        attrs: {
                          classList: "px-1 py-4",
                          processing: _vm.sending,
                          disabled: !_vm.acceptPrivacyPolicy,
                        },
                        on: { clickButton: _vm.create },
                      },
                      [_vm._v(" Apply for registration ")]
                    ),
                  ],
                  1
                ),
                _c("p", { staticClass: "text-base mb-8 text-center" }, [
                  _vm._v(
                    " We will reply within 5 business days after applying for registration. "
                  ),
                ]),
              ]),
              _c("VodAlertModal", { ref: "vodAlert" }),
            ],
            1
          ),
        ]
      ),
      _c("NoticeModal", {
        attrs: {
          modalName: "checkErrors",
          title: _vm.$t("pages.commons.errors.fix"),
          buttons: ["OK"],
        },
      }),
      _c(
        "NoticeModal",
        {
          attrs: {
            modalName: "failCreate",
            title: _vm.$t("pages.commons.create.account"),
            buttons: ["OK"],
          },
        },
        [
          _vm._v(_vm._s(_vm.$t("pages.commons.create.account_explanation"))),
          _c(
            "a",
            {
              attrs: {
                href:
                  "https://filmination.jp/" +
                  _vm.$i18n.locale +
                  "/contact.html",
                _target: "blank",
              },
            },
            [_vm._v(_vm._s(_vm.$t("components.common.here")))]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "mt-8 mb-4 text-left container mx-auto tracking-widest" },
      [_c("h1", [_vm._v("Registration application")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }